<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoHome",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoHome",
        data: [],
        row: {},
        goodness: {},
        socmed: {},
        quiz: {},
        info: {},
        news: {},
        good: [],
        ObjectName: 'Slideshow',
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData(() => {
        setTimeout(() => {
          this.$set(this.row, 'type', 'update')
        }, 500)
      })
    },
    methods: {
      submitHero() {
        BOGen.apirest('/' + this.Name, this.row, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample').hide()
            }, 3500)
          }
        }, "POST");
      },
      submitGoodness() {
        this.goodness.type="updateGood"
        BOGen.apirest('/' + this.Name, this.goodness, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.good-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.good-info')
            this.refreshData()
          }
        }, "POST");
      },
      submitSocmed() {
        this.socmed.type="updateSoc"
        BOGen.apirest('/' + this.Name, this.socmed, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.soc-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.soc-info')
            this.refreshData()
          }
        }, "POST");
      },
      submitQuiz() {
        this.quiz.type="updateQuiz"
        BOGen.apirest('/' + this.Name, this.quiz, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.quiz-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.quiz-info')
            this.refreshData()
          }
        }, "POST");
      },
      addSlide() {
        $('#collapseExample').css("display", "flex")
        this.row ={}
        this.row = {
          as_is_active: "Y",
          as_link_target:"_self",
          as_title:"",
          as_link:""
        }
        this.row.type = 'add'
      },
      editSlide(v) {
        $('#collapseExample').css("display", "flex")
        this.row = v;
        this.row.type = 'update'
        this.row.as_link_target = this.row.as_link_target == '' ? '_self' : this.row.as_link_target
      },
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
    },
    watch: {
      '$route.query'(v) {
        this.refreshData()
      },
      'row.as_title'(v){
        this.slTitleLength = v.length
      },  
    }
  }
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Slide Show</h5>
      </div>

      <div class="card-body">
        <draggable v-model="data" class="row gutter-20" tag="tbody" @end="endDrag">
          <div style="cursor:move;" class="col-lg-3 col-md-4" :id="'dat'+v.as_id" v-for="(v,k) in data" :key="k">
            <div class="wrap_slider_img">
              <img :src="uploader(v.as_img)" class="img-responsive" />
              <div class="slider_name">
                <p>{{v.as_title}}</p>
              </div>
              <a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" v-if="page.moduleRole('Edit')" class="bullet_edit"><i
                  class="ti-marker-alt"></i></a>
              <a href="javascript:;" @click="deleteItemId(v.as_id,'dat'+v.as_id,'slideshow')" v-if="page.moduleRole('Delete')" class="bullet_delete"><i
                  class="far fa-trash-alt"></i></a>
              <span class="label label-success" v-if="v.as_is_active == 'Y'">Active</span>
              <span class="label label-danger" v-else>Inactive</span>
            </div>
          </div>

          <div class="col-lg-3 col-md-4" :class="data.length > 3 ? 'mt-3':''" v-if="page.moduleRole('Add')">
            <a class="wrap_upload" data-toggle="collapse" @click="addSlide" href="#collapseExample" role="button"
              aria-expanded="false" aria-controls="collapseExample">
              <div class="ic_wrap">
                <i class="fas fa-plus"></i>
                <p>Add New Slideshow</p>
              </div>
            </a>
          </div>
        </draggable>
        <VForm @resp="submitFormSlider" method="post">
          <div class="row collapse mt-4" id="collapseExample">
            <div class="col-12 info"></div>
            <div class="col-md-4">                
              <BoField name="as_img" :label="'Banner Desktop'">
                <Uploader name="as_img" type="slider" uploadType="cropping" :param="{thumbnail:true}" v-model="row.as_img" :squarePreview="true"></Uploader>
              </BoField>
            </div>
            <div class="col-md-4 mb-3">
              <BoField name="as_img_mobile" :label="'Banner Mobile'">
                <Uploader name="as_img_mobile" type="slidermobile" title="Banner Mobile" uploadType="cropping" :param="{thumbnail:true}" v-model="row.as_img_mobile" :squarePreview="true"></Uploader>
              </BoField>
            </div>
            <div class="col-md-4">
              <BoField name="as_title" v-model="row.as_title" :attr="{type: 'text', placeholder:'Ex: Slideshow 1'}" ></BoField>
              <BoField name="as_link" v-model="row.as_link" :attr="{type: 'text', placeholder:'https://www.nutrimart.co.id'}"></BoField>
              <BoField name="as_link_target" v-model="row.as_link_target">
                <select v-model="row.as_link_target" class="form-control">
                  <option :value="'_self'">None</option>
                  <option :value="'_blank'">Blank</option>
                </select>
              </BoField>
              <BoField name="as_is_active">
                <radio name="as_is_active" v-model="row.as_is_active" option="Y"
                  :attr="validation('as_is_active')">Active</radio>
                <radio name="as_is_active" v-model="row.as_is_active" option="N">Inactive</radio>
              </BoField>
            </div>
            <div class="col-12 mt-3 text-right">
              <button type="submit" v-if="page.moduleRole('Edit')" class="btn btn-rounded btn-info btn-loading">{{row.type == 'add' ? "Add Slideshow":"Update Slideshow"}}</button>
            </div>
          </div>
        </VForm>
      </div>
    </div>

     <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Kebaikan Susu HiLo</h5>
              <router-link :to="{name: 'BoAbout'}"><strong>List</strong> <i class="ti-arrow-right ml-1 align-middle"></i></router-link>
            </div>
          </div>
          <div class="card-body ">
            <div class="row gutter-20">
              <div class="col-md-3 col-lg-20" :id="'dat'+v.ag_id" v-for="(v,k) in good" :key="k">
                <div class="wrap_slider_img">
                  <img :src="uploader(v.ag_img)" class="img-responsive" />
                  <div class="slider_name">
                    <p>{{v.ag_title}}</p>
                  </div>
                  <span class="label label-success" v-if="v.ag_is_active == 'Y'">Active</span>
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
            </div>
            <hr>
            <div class="good-info"></div>
            <VForm @resp="submitGoodness" class="row align-items-center">
              <div class="col-6">
                <BoField name="as_val" :label="'Show on Home Page?'">
                  <radio name="as_val" v-model="goodness.as_val" option="Y" :attr="validation('as_val')">Yes</radio>
                  <radio name="as_val" v-model="goodness.as_val" option="N">No</radio>
                </BoField>
              </div>
              <div class="col-6 text-right">
                <button type="submit" v-if="page.moduleRole('Edit')" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update <i class="icon-arrow-right14 position-right"></i></button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>  

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Featured Articles</h5>
              <router-link :to="{name: 'BoInfo'}"><strong>Article List</strong> <i class="ti-arrow-right ml-1 align-middle"></i></router-link>
            </div>
          </div>
          <div class="card-body ">
            <div class="row gutter-20">
              <div class="col-md-6">
                <div class="item-list collection">
                  <div class="row text-center">
                    <div class="col-md-12">
                      <div class="product-img recipe-cat mb-2">
                        <img :src="uploader(news.an_img,'250')" alt="prod">
                      </div>
                      <label class="label label-info">News Event</label>
                      <h5 class="card-title mt-1">{{(news.an_title.substr(0,40))+(news.an_title.length > 40 ? '...':'')}}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="item-list collection">
                  <div class="row text-center">
                    <div class="col-md-12">
                      <div class="product-img recipe-cat mb-2">
                        <img :src="uploader(info.an_img,'250')" alt="prod">
                      </div>
                      <label class="label label-success">Health Info</label>
                      <h5 class="card-title mt-1">{{info.an_title}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">HiLo Quiz</h5>
              <router-link :to="{name: 'BoQuiz'}"><strong>Quiz List</strong> <i class="ti-arrow-right ml-1 align-middle"></i></router-link>
            </div>
          </div>
          <div class="card-body ">
            <div class="quiz-info"></div>
            <VForm @resp="submitQuiz" class="row align-items-center">
              <div class="col-8">
                <BoField name="as_val" :label="'Show Quiz'">
                  <radio name="as_val" v-model="quiz.as_val" option="R" :attr="validation('as_val')">Randomly</radio>
                  <radio name="as_val" v-model="quiz.as_val" option="L">Sorted by Publish Date</radio>
                </BoField>
              </div>
              <div class="col-4 text-right">
                <button v-if="page.moduleRole('Edit')" type="submit" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update <i class="icon-arrow-right14 position-right"></i></button>
              </div>
            </VForm>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">HiLo Social Media</h5>
              <router-link :to="{name: 'WebSettings'}"><strong>Social Media Settings</strong> <i class="ti-arrow-right ml-1 align-middle"></i></router-link>
            </div>
          </div>
          <div class="card-body ">
            <div class="soc-info"></div>
            <VForm @resp="submitSocmed" class="row align-items-center">
              <div class="col-6">
                <BoField name="as_val" :label="'Show on Home Page?'">
                  <radio name="as_val" v-model="socmed.as_val" option="Y" :attr="validation('as_val')">Yes</radio>
                  <radio name="as_val" v-model="socmed.as_val" option="N">No</radio>
                </BoField>
              </div>
              <div class="col-6 text-right">
                <button type="submit" v-if="page.moduleRole('Edit')" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update <i class="icon-arrow-right14 position-right"></i></button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>  
      
  </div>
</template>